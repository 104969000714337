* {
    font-family: PoppinsRegular;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pop-bold {
    font-family: PoppinsBold;
}