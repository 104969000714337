@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 0.8vw;
}

* {
  /* font-family: PoppinsRegular; */
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-bg::before {
  content: "";
  display: block;
}

.leading-point-115 {
  line-height: 115%;
}

.leading-point-86 {
  line-height: 86%;
}

.button-gradient {
  background: rgb(189, 255, 0);
  background: linear-gradient(90deg, rgba(189, 255, 0, 1) 0%, rgba(96, 196, 220, 1) 50%, rgba(0, 60, 255, 1) 100%);
}

.button-gradient:hover {
  background-image: linear-gradient(90deg, rgba(189, 255, 0, 1) 0%, rgba(96, 196, 220, 1) 50%, rgba(0, 60, 255, 1) 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.text-gradient {
  background-image: linear-gradient(90deg, rgba(189, 255, 0, 1) 0%, rgba(96, 196, 220, 1) 50%, rgba(0, 60, 255, 1) 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.social-item:hover {
  /* color: grey; */
}

.social-item:active {
  /* color: grey; */
}

.title-shadow {
  text-shadow: 4px 4px 10px #000;
}

.text-spacing-9 {
  letter-spacing: -0.56rem;
}

.text-spacing--9 {
  letter-spacing: -9%;
}

.bg-gradient-gray {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 0.53) 0%, rgba(255, 255, 255, 0.53) 100%);
}

.h-124-8 {
  height: 124.8rem;
}

.text-big {
  font-size: 140rem;
  border: 1px solid red;
}

div {
  line-height: 1;
  /* overflow-x: hidden; */
}

.button-active:active {
  border: 2px solid #BDFF00;
  background: transparent;
  color: white;
}

.active-primary-color,
.social-item {
  transition: all 0.1s ease-in;
  /* border:1px solid red; */
}